import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UserSelectComponent} from './_components/user-select/user-select.component';
import {MatDialogModule} from '@angular/material/dialog';
import {EventCreatorFormComponent} from './_components/event-creator-form/event-creator-form.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {
  DateAdapter,
  MAT_DATE_FORMATS, MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {TableModule} from 'primeng/table';
import {MatIconModule} from '@angular/material/icon';
import {DateSelectorComponent} from './_components/date-selector/date-selector.component';
import {TableComponent} from './dispo/table.component';
import {UpgradeModule} from '@angular/upgrade/static';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {registerLocaleData} from '@angular/common';
import {AuthTokenInterceptor} from './common/interceptors/auth-token.interceptor';
import {AuthService} from './common/services/auth.service';
import {CalendarModule} from 'primeng/calendar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {environment} from '../environments/environment';
import {FitlerPipe} from './common/pipes/fitler.pipe';
import {MY_DATE_FORMATS, MyDateAdapter} from './utils/DatepickerAdapter';
import {UserHasPermissionsDirective} from './common/directives/permissions.directive';
import {MatMenuModule} from '@angular/material/menu';
import {ModalService} from './common/services/modal.service';
import {UserDetailsComponent} from './_components/modals/user-details/user-details.component';
import {StatisticsOverviewComponent} from './statistics-overview/statistics-overview.component';
import { EventsReactivationTableComponent } from './_components/modals/events-reactivation-table/events-reactivation-table.component';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
    declarations: [
        TableComponent,
        UserSelectComponent,
        EventCreatorFormComponent,
        DateSelectorComponent,
        FitlerPipe,
        UserHasPermissionsDirective,
        UserDetailsComponent,
        StatisticsOverviewComponent,
        EventsReactivationTableComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        UpgradeModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        BrowserAnimationsModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSelectModule,
        MatButtonModule,
        MatIconModule,
        CalendarModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        MatMenuModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: (ds: ModalService) => () => ds.load(),
            deps: [ModalService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true
        },
        {
            provide: 'tokenSvc',
            useFactory: ($injector: any) => $injector.get('tokenSvc'),
            deps: ['$injector']
        },
        {
            provide: 'appSvc',
            useFactory: ($injector: any) => $injector.get('appSvc'),
            deps: ['$injector']
        },
      {provide: MAT_DATE_LOCALE, useValue: 'de-de' },
      AuthService,
        {provide: DateAdapter, useClass: MyDateAdapter},
        {provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS},
    ],
    entryComponents: [
        EventCreatorFormComponent,
        DateSelectorComponent,
        TableComponent,
        StatisticsOverviewComponent,
        EventsReactivationTableComponent,
    ],

})
export class AppModule {
    constructor(private upgrade: UpgradeModule) {
        setRootUrl();
    }

    ngDoBootstrap() {
        this.upgrade.bootstrap(document.body, ['mmi.tp.disposition'], {strictDi: true});
    }
}

function setRootUrl() {
    window['ROOT_URL'] = environment.rootUrl;
}

