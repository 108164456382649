import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, pluck, take} from 'rxjs/operators';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    token = '';
    userData: ReplaySubject<UserData>;

    constructor(
        private readonly http: HttpClient,
        @Inject('$rootScope') private $rootScope: any,
    ) {
        this.token = localStorage.getItem(AUTH_TOKEN_KEY) || '';
        $rootScope.$on('userChanged', (event, user) => {
            this.getUser();
        });
    }


    hasWritePermission(): Observable<boolean>{
        return this.getUser()
            .pipe(
                pluck('hasDispoWriteAccess')
            )
    }

    getUser(userLogin: string = ''): Observable<UserData> {
        if (!this.userData) {
            let user = {
                login: userLogin
            };
            if (!user.login) {
                try {
                    user = JSON.parse(localStorage.getItem(USER_KEY));
                } catch (e) {
                    console.log(e);
                }
            }
            this.userData = new ReplaySubject<UserData>(1);
            this.http.get(environment.rootUrl + `/api/disposition/user/${user.login}`)
                .pipe(take(1))
                .subscribe(r => this.userData.next(r));
        }


        return this.userData;
    }

    public getToken() {
        this.http.post<any>(environment.rootUrl + '/api/auth/token', {
            'login': 'admin',
            'password': 'admin123'
        }).pipe(
            map(({token}) => token)
        ).subscribe(token => {
            this.token = token;
            localStorage.setItem(AUTH_TOKEN_KEY, token);
            location.reload();
        });


    }
}

export class UserData {
    [key: string]: any
}

const AUTH_TOKEN_KEY = 'authToken';
const USER_KEY = 'user';