import {Inject, Injectable} from '@angular/core';
import {Utils as UtilsApp} from '../../utils/Utils.app';
import {map, pluck, tap} from 'rxjs/operators';
import {cloneDeep, flatten, get} from 'lodash';
import {HttpClient} from '@angular/common/http';
import {fromEvent, Observable, ReplaySubject, zip} from 'rxjs';
import {environment} from '../../../environments/environment';
import {getAvailableUsersRoleKey, USER_ROLES_KEYS} from '../../constants/sport-event.constant';
import {
  ColorOptions,
  Competition,
  SportEvent,
  SportName,
  Team,
  UsersAvailability
} from '../models/sport-events-page.models';
import {SportEventsSharedService} from './sport-events.shared.service';
import {Utils} from '../../utils/Utils';

export class League {
  competitionIdent: string;
  competitionLabel: string;
  color: string;
}

@Injectable({
  providedIn: 'root'
})
export class SportEventsService {

  private usersAvailability: UsersAvailability = null;
  private colors: ColorOptions[];
  private usersToEventsAssignerWorker: Worker;
  private workerResponse: ReplaySubject<any>;
  workerResponse$: Observable<{ events: SportEvent[], full: boolean }>;
  formats: ReplaySubject<any>
  sportNames: ReplaySubject<SportName[]>
  teams: {
    [key: string]: ReplaySubject<Team[]>
  } = {};

  competitions: {
    [key: string]: ReplaySubject<Competition[]>
  } = {};

  constructor(
    private readonly http: HttpClient,
    @Inject('appSvc') private appSvc: any,
  ) {
    this.initWorker();
  }

  exportToExcel({start, end} = DEFAULT_DATE_PERIOD) {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    this.http.get(environment.rootUrl + `/api/planning/events/export/${startDate}/${endDate}`, {
      responseType: 'arraybuffer',
      observe: 'response'
    }).subscribe(response => {
      const [, filename] = response.headers.get('content-disposition').split(';');
      downLoadFile(response.body, {startDate, endDate}, filename.split('=')[1]);
    });
  }

  update(event: SportEvent): Observable<any> {
    return this.http.put(environment.rootUrl + '/api/planning/events/planning', SportEvent.serialize(event));
  }

  createEvent(event: Partial<SportEvent>): Observable<any> {
    return this.http.post(environment.rootUrl + '/api/planning/events', event);
  }

  updateEvent(event: Partial<SportEvent>): Observable<any> {
    return this.http.put(environment.rootUrl + '/api/planning/events/planning', event);
  }

  getDeletedSportEvents() {
    return this.http.get<SportEvent[]>(environment.rootUrl + `/api/planning/events/deleted`,)
  }

  getSportEvents({start, end} = DEFAULT_DATE_PERIOD, isDeleted?: boolean): Observable<SportEventsWithMetadata> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    const source = isDeleted ?
      this.getDeletedSportEvents()
      :
      this.http.get<Array<SportEvent[]>>(environment.rootUrl + `/api/planning/events/grouped/${startDate}/${endDate}`,);
    return zip(
      source,
      this.getColorsByDate({start, end}),
      this.getColorsForCompetitions(),

      // of(EVENTS_RESPONSE) as Observable<any>,
      // of(CELL_COLORS_REPOSNSE),
      this.getUsers({start, end}),
    )
      .pipe(
        map(([events, colors, competitionColors, users]) => {
            this.colors = colors;
            // @ts-ignore
            const groupedSortedEvents = this.groupEventsAndSort(isDeleted ? [events] : events);
            const formattedEvents = this.addPropsToEvents(groupedSortedEvents, colors);
            const eventsWithUsers = formattedEvents ? this.populateEventsWithAvailableUsers(formattedEvents, users) : new Array<SportEvent>();
            const {groupMetadata, leagues} = this.createRowGroupMetadata(eventsWithUsers, competitionColors);
            if (!isDeleted) {
              this.fixUsersIntersections(eventsWithUsers, true, colors);
            } else {
              eventsWithUsers.sort((o1, o2) => Utils.dateSorterFn(o1, o2, 'lastModDateUser'));
            }
            return {
              events: eventsWithUsers,
              competitionColors,
              groupMetadata,
              leagues: [
                ...leagues,
                ...CUSTOM_LEAGUE_COLORS
              ]
            };
          },
        )
      );
  }

  updateCellColor(color: string, colIdent: string, eventId: number): void {
    const found = this.colors.find(c => c.colorIdent === colIdent && c.sportEventId === eventId);
    const data: ColorOptions = {
      colorIdent: color,
      colIdent: Number(colIdent),
      sportEventId: eventId
    };
    if (found) {
      found.colorIdent = color;
    } else {
      this.colors.push(data);
    }
    this.http.post(environment.rootUrl + '/api/planning/colors', data).subscribe();
  }

  getFormats(): Observable<any> {
    if (!this.formats) {
      this.formats = new ReplaySubject<any>();
      this.http.get(environment.rootUrl + '/api/planning/formats',).subscribe(r => {
        this.formats.next(r)
      })
    }
    return this.formats
  }

  deleteEvent(event: SportEvent): Observable<any> {
    event.isDeleted = true;
    return this.update(event);
  }

  checkUserAvailabilityForRoleByDateAndSport(
    user: string,
    roleKey: string,
    event: SportEvent
  ): boolean {
    return SportEventsSharedService.checkUserAvailabilityForRoleByDateAndSport(
      user,
      this.usersAvailability,
      roleKey,
      event);
  }

  getColorsForCompetitions(): Observable<any> {
    return this.http.get(environment.rootUrl + '/api/planning/colors/competitions');
  }

  getColorsByDate({start, end} = DEFAULT_DATE_PERIOD): Observable<ColorOptions[]> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    return this.http.get<ColorOptions[]>(environment.rootUrl + `/api/planning/colors/${startDate}/${endDate}`,);
  }

  getSportNames(): Observable<SportName[]> {
    if (!this.sportNames) {
      this.sportNames = new ReplaySubject<SportName[]>()
      this.http.get<SportName[]>(environment.rootUrl + '/api/planning/sports')
        .subscribe(r => {
          this.sportNames.next(r)
        })
    }
    return this.sportNames
  }

  getCompetitions(sportName: string): Observable<Competition[]> {
    if(!this.competitions[sportName]) {
      this.competitions[sportName] = new ReplaySubject<Competition[]>()
      this.http.get<Competition[]>(environment.rootUrl + '/api/planning/competitions/' + sportName)
        .subscribe(r => {
          this.competitions[sportName].next(r);
        });
    }
    return this.competitions[sportName]
  }

  getTeams(sportName: string): Observable<Team[]> {
    if (!this.teams[sportName]) {
      this.teams[sportName] = new ReplaySubject<Team[]>();
      this.http.get<Team[]>(environment.rootUrl + '/api/planning/teams/' + sportName)
        .subscribe(r => {
          this.teams[sportName].next(r)
        });
    }
    return this.teams[sportName]
  }

  addUserAvailabilityForEventsByDate(user: string, except: SportEvent, events: SportEvent[]) {
    const arr = SportEventsSharedService.getEventsUserPresentInByDate(user, except.dateToGetAvailableUsers, events);
    if (arr.length) {
      return;
    }
    return SportEventsSharedService.addUserAvailabilityForEventsByDate(
      user,
      this.usersAvailability,
      except,
      events
    );
  }

  private createRowGroupMetadata(items: SportEvent[], competitionColors: any): { groupMetadata: any, leagues: League[] } {
    const groupMetadata = {};
    let leagues: League[] = [];
    for (let i = 0; i < items.length; i++) {
      const rowData = items[i];
      let groupName;
      if (rowData.gameDay === null) {
        groupName = `${UtilsApp.toFormatDate(rowData.programStart)}${rowData.competitionName}`;
      } else {
        groupName = `${rowData.gameDay}${rowData.competitionName}`;
      }
      addLeague(rowData, competitionColors);
      items[i].groupName = groupName;
      if (i == 0) {
        groupMetadata[groupName] = {index: 0, size: 1};
      } else {
        const previousRowData = items[i - 1];
        const previousRowGroupName = previousRowData.groupName;
        if (groupName === previousRowGroupName) {
          groupMetadata[groupName].size++;
        } else {
          groupMetadata[groupName] = {index: i, size: 1};
        }
      }
    }

    return {groupMetadata, leagues};


    function addLeague<T extends SportEvent>(
      {competitionIdent, competitionLabel}: T,
      competitionColors: any
    ) {
      if (leagues.find(league => league.competitionLabel === competitionLabel)) {
        return;
      }
      leagues =
        [...leagues,
          {
            competitionIdent,
            competitionLabel,
            color: competitionColors[competitionIdent] || 'aquablue'
          }
        ];
    }
  }

  fixUsersIntersections(events: SportEvent[], full: boolean, colors: ColorOptions[] = this.colors) {
    this.usersToEventsAssignerWorker.postMessage({events, colors});
  }

  clearState(): void {
    this.usersAvailability = null;
    this.initWorker();
  }

  getUserDetails(user: string): Observable<any> {
    return this.http.get(environment.rootUrl + `/api/planning/statistics/${user}`);
  }

  private initWorker() {
    if (typeof Worker !== 'undefined') {
      if (!this.usersToEventsAssignerWorker) {
        this.usersToEventsAssignerWorker = new Worker('../web-workers/events-user-assigner.worker', {type: 'module'});
        fromEvent(this.usersToEventsAssignerWorker, 'message')
          .subscribe((msg) => {
            this.workerResponse.next(msg);
          });
      }
      this.workerResponse = new ReplaySubject(1);
      this.workerResponse$ = this.workerResponse.asObservable().pipe(pluck('data'));
    } else {
      console.log('Workers are not supported, please contact server administrator to get complete experience');
    }

  }

  private getUsers({start, end} = DEFAULT_DATE_PERIOD): Observable<UsersAvailability> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    return this.http.get<any>(environment.rootUrl + `/api/planning/users/${startDate}/${endDate}`,)
      // return of(USERS_REPOSNSE)
      .pipe(
        map(mapUsersResponseToEventsUserRolesKeys),
        tap(data => this.usersAvailability = cloneDeep(data)),
      );
  }


  private addPropsToEvents(events, colors: ColorOptions[]) {
    return events && events.length > 0 ? events.map(event => {
      const coloredCells = colors.filter(option => option.sportEventId === event.id);
      const formattedColors = {};
      coloredCells.forEach(option => {
        formattedColors[option.colIdent] = option.colorIdent;
      });
      return {
        ...event,
        colors: formattedColors,
        dateFormatted: UtilsApp.toFormatDate(event.programStart, 'dd.LL'),
        dateToGetAvailableUsers: UtilsApp.toFormatDate(event.programStart, 'yy MM dd').replace(/ /g, '')
      };
    }) : events;
  }

  private populateEventsWithAvailableUsers(events: SportEvent[], users: UsersAvailability): SportEvent[] {
    events.forEach(event => {
      USER_ROLES_KEYS.forEach(roleKey => {
        const availableUsers = get(users, `${event.dateToGetAvailableUsers}.${event.sportName}.${roleKey}`) || [];
        event[getAvailableUsersRoleKey(roleKey)] = availableUsers;
      });
    });
    return events;
  }

  private groupEventsAndSort(groups: Array<SportEvent[]>) {
    groups.forEach((g, index) => g.unshift(new SportEvent()));
    groups[0] = groups[0] ? groups[0].slice(1, groups[0].length) : undefined;
    var flattened = flatten(groups);
    if (flattened && flattened.length && flattened[0] === undefined) {
      flattened = SportEvent[0];
    }
    return flattened;
  }

}

function mapUsersResponseToEventsUserRolesKeys(users: UsersAvailability): UsersAvailability {
  Object.values(users).forEach(competitionGroupByDate => {
    // todo mocked
    // competitionGroupByDate['BBL'] = competitionGroupByDate['Eishockey'];

    Object.values(competitionGroupByDate).forEach(userGroupByCompetition => {
      Object.entries(ROLES_DICTIONARY).forEach(([eventResponseKey, userResponseKey]) => {
        userGroupByCompetition[eventResponseKey] = userGroupByCompetition[userResponseKey];
        // if (userGroupByCompetition[eventResponseKey].length) {
        //   userGroupByCompetition[eventResponseKey].push('additional mocked user')
        // }
      });
    });
  });
  return users;
}


// key - sportevent response, value - users response
const ROLES_DICTIONARY = {
  'roleCommenter': 'KOM',
  'roleRegie': 'Regie',
  'roleEditor': 'RED',
  'rolePresenter': 'Moderation',
  'roleExpert': 'EXP',
  'roleMaz': 'MAZ'
};

const start = new Date();
const end = new Date().setFullYear(new Date().getFullYear() + 1);

export const DEFAULT_DATE_PERIOD = {
  start: +start,
  end: +end
};

export const CUSTOM_LEAGUE_COLORS = [
  {competitionLabel: 'grün', color: 'rgb(102,255,102)', competitionIdent: null},
  {competitionLabel: 'rot', color: 'red', competitionIdent: null},
  {competitionLabel: 'magenta', color: 'magenta', competitionIdent: null},
  {competitionLabel: 'Farbe entfernen', color: '', competitionIdent: null}
];

type SportEventsWithMetadata = {
  events: SportEvent[],
  competitionColors: any,
  groupMetadata: any,
  leagues: League[]
}

function downLoadFile(data: any, {startDate, endDate}, name?: string) {
  let blob = new Blob([data], {type: 'application/ms-excel'});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  if (name) {
    a.download = name;
  } else {
    a.download = `Dispo-Table_${startDate}-${endDate}.xlsx`;
  }
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
}
