import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FitlerPipe implements PipeTransform {

  transform(input: any[], filterQuery: string, filterField: string): unknown {
    return (input && input.filter(i => i[filterField].includes(filterQuery))) || [];
  }

}
