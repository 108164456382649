export const USER_ROLES_KEYS = ['roleCommenter', 'roleRegie', 'roleEditor', 'rolePresenter', 'roleExpert', 'roleMaz'];
export const USER_ROLE_COLUMN_STARTING_INDEX = 7;

export function getAvailableUsersRoleKey(key: string) {
  return key + AVAILABLE_USERS_ADDITIONAL_KEY;
}

export const AVAILABLE_USERS_ADDITIONAL_KEY = 'UsersArray';

export const DEFAULT_USER = 'N.N'
