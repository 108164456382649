import {Component, OnInit} from '@angular/core';
import {COLORS, CompetitionData, Role, StatisticsOverviewService, UserData} from './statistics-overview.service';
import {DEFAULT_DATE_PERIOD, SportEventsService} from '../common/services/sport-events.service';
import {DateSelectorComponent} from '../_components/date-selector/date-selector.component';
import {debounceTime, filter, take, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AutoDestroyService} from '../common/services/autodestroy.service';
import {Subject} from 'rxjs';

export const STATISTICS_OVERVIEW_SELECTOR = 'app-statistics-overview';

@Component({
    selector: STATISTICS_OVERVIEW_SELECTOR,
    templateUrl: './statistics-overview.component.html',
    styleUrls: ['./statistics-overview.component.scss'],
    providers: [AutoDestroyService]
})
export class StatisticsOverviewComponent implements OnInit {

    constructor(
        private readonly statisticsService: StatisticsOverviewService,
        private readonly sportEventService: SportEventsService,
        private readonly dialog: MatDialog,
        private readonly destroy$: AutoDestroyService
    ) {
    }

    selectedCell: SelectedCell;
    editingPlanningTarget: string = '';
    users: UserData[] = [];
    roles: Role[] = [];
    selectedRole: Role = null;
    competitions: CompetitionData[] = [];
    selectedDateRange = DEFAULT_DATE_PERIOD;
    private changeDebouncer = new Subject<{ value: string, competition: CompetitionData, data: UserData, color?: COLORS }>();

    getColorByState = this.statisticsService.getColorByState.bind(this.statisticsService);

    ngOnInit(): void {
        this.statisticsService.getRoles().pipe(
            takeUntil(this.destroy$)
        ).subscribe(roles => {
            this.roles = roles;
            this.selectedRole = roles[0];
            this.loadData();
        });

        this.changeDebouncer.pipe(
            debounceTime(500)
        )
            .subscribe(({competition, value, data, color}) => {
                this.updateUserDetails(value, data, competition, color);
            });

    }

    roleSelected(role: Role) {
        this.selectedRole = role;
        this.loadData();
    }

    loadData(): void {
        this.statisticsService.getStatistics(this.selectedRole, this.selectedDateRange)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(r => {
                this.users = [...r.users, null];
                this.competitions = r.competitions;
            });
    }

    changeColor(data: UserData, competition: CompetitionData, color: COLORS) {
        this.changeDebouncer.next({
            value: null, data, competition, color
        });
    }

    sollChanged(data: UserData, competition: CompetitionData) {
        this.changeDebouncer.next({
            value: this.editingPlanningTarget, data, competition
        });
    }

    selectDate() {
        this.dialog.open(DateSelectorComponent, {
            width: '350px',
            data: this.selectedDateRange
        })
            .afterClosed()
            .pipe(
                filter(Boolean),
                take(1)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(({dateStart, dateEnd}) => {
                this.selectedDateRange = {
                    start: dateStart,
                    end: dateEnd
                };
                this.loadData();
            });
    }

    onCellSelected(cell: SelectedCell) {
        const rowData = cell.data;
        const competition = cell.index;
        this.editingPlanningTarget = String(rowData.competitionStatistics[competition.competitionName]?.userDetails?.planningTarget);
        this.selectedCell = cell;
    }

    onCellSelectionOff() {
        switch (this.selectedCell.field) {
            case 'Soll':
                this.sollChanged(this.selectedCell.data, this.selectedCell.index);
                break;
            case 'Ist':
                break;
        }
        this.selectedCell = null;
    }

    onCellSelectionCancel() {
        this.selectedCell = null;
        this.editingPlanningTarget = '';
    }

    private updateUserDetails(value: string, data: UserData, competition: CompetitionData, color?: COLORS) {
        this.statisticsService.updateUserDetails(value, data, competition, this.selectedRole, color)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(r => {
            });
    }

}

class SelectedCell {
    data: UserData;
    field: string;
    index: CompetitionData;
}
