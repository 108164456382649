import {Component, Inject, OnInit} from '@angular/core';
import {SportEventsService} from '../../../common/services/sport-events.service';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AutoDestroyService} from '../../../common/services/autodestroy.service';
import {SportEvent} from '../../../common/models/sport-events-page.models';
import {takeUntil} from 'rxjs/operators';
import {Utils} from '../../../utils/Utils';

@Component({
  selector: 'app-events-reactivation-table',
  templateUrl: './events-reactivation-table.component.html',
  styleUrls: ['./events-reactivation-table.component.scss'],
  providers: [AutoDestroyService]
})
export class EventsReactivationTableComponent implements OnInit {

  constructor(
    private sportEventService: SportEventsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private destroy: AutoDestroyService
  ) {
  }

  loading = true;
  events: SportEvent[] = null

  ngOnInit(): void {
    this.sportEventService.getDeletedSportEvents()
      .pipe(
        takeUntil(this.destroy)
      ).subscribe(events => {
      this.events = events.sort((o1, o2) => Utils.dateSorterFn(o1, o2, 'lastModDateUser'));
      this.loading = false;
    })
  }

  undoDeletion(event: SportEvent) {
    this.sportEventService.update({
      ...event,
      isDeleted: false,
    })
      .pipe(
        takeUntil(this.destroy)
      )
      .subscribe(() => {
        this.events.splice(this.events.findIndex(e => e === event), 1)
      })
    ;
  }

}
