import {Component, Inject, ViewChild} from '@angular/core';
import {SportEventsService} from '../../common/services/sport-events.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    switchMap,
    take,
    takeUntil,
    tap
} from 'rxjs/operators';
import {Calendar} from 'primeng/calendar';
import {Competition, SportEvent, SportName, Team} from '../../common/models/sport-events-page.models';
import {Utils} from '../../utils/Utils.app';
import {AutoDestroyService} from '../../common/services/autodestroy.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {trigger} from '@angular/animations';

@Component({
    animations: [trigger('', [])],
    selector: 'app-event-creator-form',
    templateUrl: './event-creator-form.component.html',
    styleUrls: ['./event-creator-form.component.scss'],
    providers: [
        AutoDestroyService
    ]
})
export class EventCreatorFormComponent {

    @ViewChild('start', {static: false}) startDatePicker: Calendar;

    formats: { value: string, viewValue: string }[] = [];
    sportNames: SportName[] = [];
    selectedSportName = new BehaviorSubject<string>('');
    competitions: Observable<Competition[]>;
    teams: Observable<Team[]>;
    form: FormGroup;
    editMode = false;
    loading: boolean

    constructor(
        private readonly sportEventService: SportEventsService,
        private readonly destroy$: AutoDestroyService,
        public dialogRef: MatDialogRef<EventCreatorFormComponent>,
        @Inject(MAT_DIALOG_DATA) private readonly data: { event: SportEvent }
    ) {
        const {event} = data;
        if (event) {
            this.selectedSportName.next(event.sportName);
            this.editMode = !!event;
        }
        this.sportEventService.getFormats()
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(formats => {
                this.formats = formats.filter(Boolean).map(format => ({value: format, viewValue: format}));
            });

        this.sportEventService.getSportNames()
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(sportNames => {
                this.sportNames = sportNames as SportName[];
            });

        this.buildForm(event);
        this.setupDatasource();

    }

    get formatValue() {
        return this.form.get('Format').value;
    }

    createItem() {
        if (this.form.invalid) {
            console.log(this.form);
            return;
        }

        this.loading = true;
        if(this.editMode) {
            const objToSave = {
                ...this.data.event
            };
            const formattedForm = this.formatForm();
            Object.keys(formattedForm).forEach(key => {
                objToSave[Utils.lowerCaseFirstLetter(key)] = formattedForm[key]
            })
            return this.sportEventService.updateEvent(objToSave)
                .pipe(
                    takeUntil(this.destroy$)
                )
                .subscribe(s => {
                    this.dialogRef.close(true);
                });
        }

        this.sportEventService.createEvent(this.formatForm())
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(s => {
                this.dialogRef.close(true);
            });
    }

    dateSelected(date: Date, key: string) {
        this.form.patchValue({
            [key]: Utils.toFormatDate(date.toString(), 'dd.MM.yyyy HH:mm')
        });
    }

    private formatForm() {
        const value = this.form.getRawValue();
        return {
            ...value,
            GameDay: value.GameDay ? +value.GameDay : undefined,
            ProgramStart: formatDate(value.ProgramStart),
            ProgramEnd: formatDate(value.ProgramEnd),
            Kickoff: formatDate(value.Kickoff),
        };

        function formatDate(inputDate: string) {
            if(!inputDate) return undefined
            const [date, time] = inputDate.split(' ');
            const [day, month, year] = date.split('.');
            return `${year}-${month}-${day}T${time}`;
        }
    }

    private buildForm(event?: SportEvent) {
        if (event) {
            return this.buildEditForm(event);
        }
        this.buildCreateForm();

    }

    ngAfterViewInit() {
        if(this.editMode){
            const date = new Date(this.data.event.programStart)
            this.startDatePicker.updateModel(date)
        }
    }

    private buildEditForm(event: SportEvent) {
        this.form = new FormGroup({
            SportName: new FormControl({value: event.sportName, disabled: true}, [Validators.required]),
            CompetitionName: new FormControl({value: event.competitionName, disabled: true}, [Validators.required]),
            CompetitionLabel: new FormControl({value: event.competitionLabel, disabled: false}, [Validators.required]),
            GameDay: new FormControl(event.gameDay, []),
            ProgramStart: new FormControl(Utils.toFormatDate(event.programStart, 'dd.MM.yyyy HH:mm'), []),
            TeamNameHome: new FormControl(event.teamNameHome, []),
            TeamNameAway: new FormControl(event.teamNameAway, []),
            Format: new FormControl(event.format, []),
            isAutoSyncDisabled: new FormControl(event.isAutoSyncDisabled, [])

        });
    }

    private buildCreateForm() {
        const debounce = 200;

        const teamHomeIdControl = new FormControl('', [Validators.required]);
        teamHomeIdControl.valueChanges.pipe(
            takeUntil(this.destroy$),
            filter(Boolean),
            distinctUntilChanged(),
            debounceTime(debounce),
            switchMap(id => this.teams.pipe(take(1), map(teams => teams.find(t => t.id === Number(id)))))
        )
            .subscribe(team => {
                this.form.patchValue({
                    TeamHomeName: team.name
                });
            });

        const teamAwayIdControl = new FormControl('', [Validators.required]);
        teamAwayIdControl.valueChanges.pipe(
            takeUntil(this.destroy$),
            filter(Boolean),
            distinctUntilChanged(),
            debounceTime(debounce),
            switchMap(id => this.teams.pipe(take(1), map(teams => teams.find(t => t.id === Number(id)))))
        )
            .subscribe(team => {
                this.form.patchValue({
                    TeamAwayName: team.name
                });
            });

        const sportNameControl = new FormControl('', [Validators.required]);
        sportNameControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                distinctUntilChanged(),
                debounceTime(debounce),
                tap(name => this.selectedSportName.next(name))
            ).subscribe(name => {
            this.form.patchValue({
                CompetitionId: '',
                TeamHomeId: null,
                TeamAwayId: null,
                SportId: this.sportNames.find(sport => sport.name === name).id
            });
        });
        this.form = new FormGroup({
            SportName: sportNameControl,
            SportId: new FormControl('', [Validators.required]),
            CompetitionId: new FormControl('', [Validators.required]),
            CompetitionLabel: new FormControl('', []),
            CompetitionName: new FormControl('', []),
            GameDay: new FormControl('', []),
            ProgramStart:  new FormControl('', [Validators.required]),
            ProgramEnd: new FormControl('', []),
            TeamHomeId: teamHomeIdControl,
            TeamHomeName: new FormControl('', []),
            TeamAwayId: teamAwayIdControl,
            TeamAwayName: new FormControl('', []),
            Format: new FormControl('', []),
            isAutoSyncDisabled: new FormControl('', [])
        });
    }

    private setupDatasource() {
        this.teams = this.selectedSportName.pipe(
            filter(Boolean),
            switchMap((sportname: string) => this.sportEventService.getTeams(sportname)),
            shareReplay({
                refCount: true,
                bufferSize: 1
            })
        );

        this.competitions = this.selectedSportName.pipe(
            filter(Boolean),
            switchMap((sportname: string) => this.sportEventService.getCompetitions(sportname)),
            shareReplay({
                refCount: true,
                bufferSize: 1
            })
        );
    }

}
