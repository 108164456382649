import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {downgradeComponent} from '@angular/upgrade/static';
import {TABLE_COMPONENT_SELECTOR, TableComponent} from './app/dispo/table.component';
import {
  STATISTICS_OVERVIEW_SELECTOR,
  StatisticsOverviewComponent
} from './app/statistics-overview/statistics-overview.component';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// @ts-ignore
const angular = window['angular'];


const angularModule =
  angular.module('mmi.tp.disposition');

// @ts-ignore

[{
  component: TableComponent,
  selector: TABLE_COMPONENT_SELECTOR
}, {
  component: StatisticsOverviewComponent,
  selector: STATISTICS_OVERVIEW_SELECTOR
}]
  .map(c => ({
    downgraded: downgradeComponent({component: c.component, propagateDigest: true}),
    selector: toCamelCase(c.selector)
  }))
  .forEach(c => {
    angularModule
      .directive(c.selector, c.downgraded);
  });

function toCamelCase(selector: string): string {
  return selector.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}
