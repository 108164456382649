import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Optional,
  ViewChild
} from '@angular/core';
import {fromEvent, interval, merge, Observable, Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {trigger} from '@angular/animations';
import {DateSelectorComponent} from '../_components/date-selector/date-selector.component';
import {DEFAULT_DATE_PERIOD, League, SportEventsService,} from '../common/services/sport-events.service';
import {EventCreatorFormComponent} from '../_components/event-creator-form/event-creator-form.component';
import {debounceTime, filter, take, takeUntil} from 'rxjs/operators';
import {
  AVAILABLE_USERS_ADDITIONAL_KEY,
  DEFAULT_USER,
  USER_ROLE_COLUMN_STARTING_INDEX,
  USER_ROLES_KEYS
} from '../constants/sport-event.constant';
import {SportEvent} from '../common/models/sport-events-page.models';
import {SportEventsSharedService} from '../common/services/sport-events.shared.service';
import {cloneDeep} from 'lodash';
import {AutoDestroyService} from '../common/services/autodestroy.service';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatSelect} from '@angular/material/select';
import {AuthService} from '../common/services/auth.service';
import {EventsReactivationTableComponent} from '../_components/modals/events-reactivation-table/events-reactivation-table.component';

export const TABLE_COMPONENT_SELECTOR = 'app-table-component';

@Component({
    animations: [trigger('', [])],
    selector: TABLE_COMPONENT_SELECTOR,
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    providers: [AutoDestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements AfterContentInit {

    @ViewChild('table', {static: true}) table: ElementRef<HTMLElement>;
    @ViewChild('header', {static: true}) header: ElementRef<HTMLElement>;
    @ViewChild('autocompleteTrigger', {static: false}) autocomplete: MatAutocompleteTrigger;
    @ViewChild('select', {static: false}) select: MatSelect;

    saveEventDebouncer = new Subject<SportEvent>();
    formats: { value: string, viewValue: string }[] = [];
    AVAILABLE_USERS_ADDITIONAL_KEY = AVAILABLE_USERS_ADDITIONAL_KEY;
    DEFAULT_USER = DEFAULT_USER;
    USER_ROLE_KEYS = USER_ROLES_KEYS;
    USER_ROLE_COLUMN_STARTING_INDEX = USER_ROLE_COLUMN_STARTING_INDEX;
    selectedCell: SelectedCell;
    tableHeight = '800px';
    editable: boolean;
    rowGroupMetadata: { [key: string]: { index: number, size: number } };
    leagues: League[] = [];
    leaguesCache: League[] = [];
    events: SportEvent[] = [];
    selectedLeague: string;
    selectedDateRange = DEFAULT_DATE_PERIOD;
    competitionColors: { [key: string]: string } = {};
    loading: boolean;
    isShowingDeleted: boolean

    constructor(
        private readonly sportEventService: SportEventsService,
        private readonly cdr: ChangeDetectorRef,
        private readonly authService: AuthService,
        private readonly dialog: MatDialog,
        private readonly destroy$: AutoDestroyService,
        private readonly elRef: ElementRef,
        @Optional() @Inject(MAT_DIALOG_DATA) private readonly data: any
    ) {
        this.isShowingDeleted = data && data.isShowingDeleted
    }

    ngOnInit() {
        this.initListeners();
        this.initDatasource();
    }

    ngOnDestroy(): void {
        this.sportEventService.clearState();
    }

    ngAfterContentInit(): void {
        scrollBarHeight = getScrollBarWidth();
        this.initResizeListener();
    }

    saveEvent(event: SportEvent) {
        this.loading = true;
        this.sportEventService.update(event)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.loading = false;
                this.cdr.detectChanges();
            });
    }

    export() {
        this.sportEventService.exportToExcel(this.selectedDateRange);
    }

    undoDeletion(event: SportEvent) {
        this.loading = true;
        this.sportEventService.update({
            ...event,
            isDeleted: false,
        })
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.initDatasource();
            })
        ;
    }

    deleteEvent(event: SportEvent) {
        if (event.isDeleted) {
            return;
        }
        this.loading = true;
        this.sportEventService.deleteEvent(event)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                this.initDatasource();
            })
        ;
    }

    selectFullSeason() {
        const start = new Date();
        const end = new Date();
        const isSeasonEnds = start.getMonth() < 6; // july == 6 - is the start of the season
        if (isSeasonEnds) {
            start.setFullYear(start.getFullYear() - 1);
        } else {
            end.setFullYear(end.getFullYear() + 1);
        }

        start.setMonth(6);
        start.setDate(1);
        start.setHours(0);
        start.setMinutes(0);
        end.setMonth(5);
        end.setDate(30);
        end.setHours(0);
        end.setMinutes(0);
        this.selectedDateRange = {
            start: +start,
            end: +end
        };
        this.initDatasource();
    }

    selectDate() {
        this.dialog.open(DateSelectorComponent, {
            width: '350px',
            data: this.selectedDateRange
        })
            .afterClosed()
            .pipe(
                filter(Boolean),
                take(1)
            )
            .pipe(takeUntil(this.destroy$))

            .subscribe(({dateStart, dateEnd}) => {
                this.selectedDateRange = {
                    start: +dateStart,
                    end: +dateEnd
                };
                this.initDatasource();
            });
    }

    showDeleted() {
        this.dialog.open(EventsReactivationTableComponent, {
            width: '98ww',
            panelClass: 'no-padding',
            data: {
                isShowingDeleted: true,
            }
        }).afterClosed()
            .pipe(
                takeUntil(this.destroy$),
            )
            .subscribe(() => {
                this.initDatasource();
            });
    }

    editEvent(event: SportEvent): void {
        this.openEditingModal(event)
            .pipe(
                takeUntil(this.destroy$),
                filter(Boolean)
            )
            .subscribe(() => {
                this.initDatasource();
            });
    }

    navigateToStatistics(): void {
      location.href = '/#!/statistics-overview';
    }

    createNewEvent(): void {
        this.openEditingModal()
            .pipe(
                takeUntil(this.destroy$),
                filter(Boolean)
            )
            .subscribe(() => {
                this.initDatasource();
            });
    }

    onCellSelectionOff() {
        const {field, data} = this.selectedCell;
        if (field == '6' || field == '12') {
            // 6 === format field
            // 12 === Gast field
            this.saveEvent(data);
        }
        this.selectedCell = null;
        this.leagues = this.leaguesCache;
        setTimeout(() => {
            this.header.nativeElement.dispatchEvent(new Event('resize'));
        }, 0);
        this.cdr.detectChanges();
    }

    onCellSelected(data: SelectedCell) {
        if(this.isShowingDeleted) return;
        this.selectedCell = data;
        this.selectedLeague = data.data.competitionName;
        this.leagues = this.leagues
            .filter(league => league.competitionIdent === null || (league.competitionLabel === data.data.competitionLabel));
        setTimeout(() => {
            if (this.autocomplete) {
                this.autocomplete.openPanel();
            }
            if (this.select) {
                this.select.open();
            }
            this.header.nativeElement.dispatchEvent(new Event('resize'));
        });
        this.cdr.detectChanges();
    }

    applyColor(colorCode: string) {
        if (!this.selectedCell) {
            return;
        }
        this.selectedCell.data.colors[this.selectedCell.field] = colorCode;
        this.sportEventService.updateCellColor(colorCode, this.selectedCell.field, this.selectedCell.data.id);
    }

    eventUserChanged(newUserName: string, event: SportEvent, roleKey: string) {
        const oldUserName = event[roleKey];
        event[roleKey] = newUserName;
        if (oldUserName !== DEFAULT_USER) {
            this.addUserAvailabilityForEventsByDate(oldUserName, event);
            this.sportEventService.fixUsersIntersections(
                this.events.filter(curEvent => curEvent.dateToGetAvailableUsers === event.dateToGetAvailableUsers), false
            );
        }
        this.saveEvent(event);
        this.cdr.detectChanges();
    }

    roleAdditionalsChanged(event: SportEvent): void {
        this.saveEvent(event);
        this.cdr.detectChanges();
    }

    trackBy(index, item: SportEvent) {
        return item.id;
    }

    getUserRoleBgColor(event: SportEvent, roleValue, index) {
        return roleValue === DEFAULT_USER ? 'red' : event.colors[index + USER_ROLE_COLUMN_STARTING_INDEX];
    }

    private openEditingModal(event?: SportEvent): Observable<any> {
        return this.dialog.open(EventCreatorFormComponent, {
            width: '450px',
            panelClass: 'no-padding',
            data: {
                event
            }
        }).afterClosed();
    }

    private initResizeListener() {
        const interval$ = interval(200)
            .pipe(
                take(20)
            );
        merge(
            fromEvent(this.header.nativeElement, 'resize'),
            fromEvent(window, 'resize'),
            interval$
        )
            .pipe(
                debounceTime(100),
            )

            .pipe(takeUntil(this.destroy$))

            .subscribe(() => this.resizeTable());
    }

    private resizeTable() {
        const {top} = this.elRef.nativeElement.getBoundingClientRect();
        const ww = window.innerWidth;
        this.tableHeight =
            `${window.innerHeight - this.header.nativeElement.offsetHeight
            - 30 - top - 5 - (ww < 1200 ? scrollBarHeight : 0)}px`; //-72
        this.cdr.detectChanges();
    }

    private removeUserAvailabilityForEventsByDate(user: string, except: SportEvent): void {
        SportEventsSharedService.removeUserAvailabilityForEventsByDate(user, this.events, except.dateToGetAvailableUsers, except);
    }

    private addUserAvailabilityForEventsByDate(user: string, except: SportEvent) {
        this.sportEventService.addUserAvailabilityForEventsByDate(user, except, this.events);
    }

    private initListeners() {
        this.saveEventDebouncer.pipe(
            debounceTime(500)
        ).subscribe(event => this.saveEvent(event));

        this.sportEventService.workerResponse$
            .pipe(takeUntil(this.destroy$))
            .subscribe(({events, full}) => {
                if(full){
                    this.events = events;
                } else {
                    events.forEach(event => {
                        const foundIndex = this.events.findIndex(e => e.id === event.id);
                        this.events[foundIndex] = event;
                    });
                }
                this.cdr.detectChanges();
            });
    }

    private initDatasource() {
        this.loading = true;
        this.cdr.detectChanges();

        this.saveEventDebouncer.pipe(
            debounceTime(500)
        ).subscribe(event => this.saveEvent(event));


        this.authService.hasWritePermission()
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(permission => {
                this.editable = permission && !this.isShowingDeleted;
            });

        this.sportEventService.getFormats()
            .pipe(takeUntil(this.destroy$))
            .subscribe(formats => {
                this.formats = formats.filter(Boolean).map(format => ({value: format, viewValue: format}));
            });

        this.sportEventService.getSportEvents(this.selectedDateRange, this.isShowingDeleted)
            .pipe(takeUntil(this.destroy$))
            .subscribe(({events, competitionColors, groupMetadata, leagues}) => {
                this.competitionColors = competitionColors;
                this.events = events;
                this.rowGroupMetadata = groupMetadata;
                this.leaguesCache = cloneDeep(leagues);
                this.leagues = leagues;
                this.loading = false;
                this.resizeTable();
            });
    }


}

function getEventsByDate(dayCode: string, items: SportEvent[]) {
    return items.filter(i => i.dateToGetAvailableUsers === dayCode);
}

class SelectedCell {
    data: SportEvent;
    field: string;
}

function getScrollBarWidth() {
    var inner = document.createElement('p');
    inner.style.width = '100%';
    inner.style.height = '200px';

    var outer = document.createElement('div');
    outer.style.position = 'absolute';
    outer.style.top = '0px';
    outer.style.left = '0px';
    outer.style.visibility = 'hidden';
    outer.style.width = '200px';
    outer.style.height = '150px';
    outer.style.overflow = 'hidden';
    outer.appendChild(inner);

    document.body.appendChild(outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) {
        w2 = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return (w1 - w2);
};

let scrollBarHeight = 0;

