import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserDetailsComponent} from '../../_components/modals/user-details/user-details.component';


@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    private readonly dialog: MatDialog,
  ) {}


  load() {
    window.addEventListener('openModal', (e: CustomEvent) => {
      this.dialog.open(UserDetailsComponent, {
        width: '600px',
        panelClass: 'no-padding',
        data: e.detail
      })
    })
  }

}
