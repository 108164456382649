import {Utils} from '../../utils/Utils';

export class User {
  id: string;
  name: string;
}

export class SportName {
  abbreviation: string
  id: string
  name: string
}

export class Competition {
  competitionInternal: number
  competitionLabelAkamai: string
  externalId: string
  id: number
  imageFolder: string
  isTcsYouTubeExportActive: boolean
  name: string
  productAbbrevMap: string
  speakingIdPrefix: string
  speakingIdYearFormat: string
  sportId: number
  startOffset: number
  syncLightningTable: boolean
  syncPlayersAndLineups: boolean
}

export class Team {
  sbbreviation: string;
  cityName: string;
  externalId: number
  id: number
  name: string;
  sportType: string;
}

export class SportEvent {
  static COUNTER = 0;
    isAutoSyncDisabled: boolean;

  constructor() {
    this.id = Math.random() + ++SportEvent.COUNTER
    this.fake = true;
  }

  'id': number;
  'sportEventId': number;
  'competitionName': string;
  competitionIdent: string;
  'competitionLabel': string;
  'programStart': string;
  'gameDay': number;
  'teamNameHome': string;
  'teamNameAway': string;
  'format': string;
  'roleCommenter': string;
  'roleRegie': string;
  'roleEditor': string;
  'rolePresenter': string;
  'roleExpert': string;
  'roleMaz': string;
  'roleAdditionals': string;
  'color': string;
  'lastModDateUser': string;
  'lastModDateAutoSync': string;
  'isDeleted': boolean;
  'sportName': string
  dateToGetAvailableUsers?: string;
  virtualMatchDay?: number;
  dateFormatted?: string;
  groupName?: string;
  // colors - key = name of the field, value = code of the color
  colors?: {
    [key: string]: string
  }
  fake?: boolean;


  static serialize(obj: SportEvent) {
    const result = {} as SportEvent;
    SportEvent.keysToStoreOnBackend.forEach(key => {
      result[key] = obj[Utils.lowerCaseFirstLetter(key)]
    });
    return result;
  }

  static keysToStoreOnBackend = [
    'Id',
    'SportEventId',
    'CompetitionIdent',
    'CompetitionName',
    'CompetitionLabel',
    'Format',
    'TeamNameHome',
    'TeamNameAway',
    'SportName',
    'GameDay',
    'ProgramStart',
    'ProgramEnd',
    'Kickoff',
    'RoleCommenter',
    'RoleRegie',
    'RolePresenter',
    'RoleEditor',
    'RoleExpert',
    'RoleMaz',
    'RoleAdditionals',
    'isDeleted',
    'isAutoSyncDisabled'
  ]
}

export interface ColorOptions {
  sportEventId: number;
  colIdent: number
  colorIdent: string
}

export class UsersAvailability {
  // date
  [key: string]: {
    // competitionName
    [key: string]: {
      // role
      [key: string]: string[]
    }
  }
}
