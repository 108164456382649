import {NativeDateAdapter} from '@angular/material/core';
import {Injectable} from "@angular/core";

export const MY_DATE_FORMATS = {
  parse: {
    // dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
    dateInput: 'input'
  },
  display: {
    // dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@Injectable()
export class MyDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat == 'input') {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      return this._to2digit(day) + '.' + this._to2digit(month) + '.' + year;
    } else {
      return date.toDateString();
    }
  }

  parse(value: any): Date | null {
    const [day, month, year] = value.split('.');
    const date = new Date()
    date.setFullYear(year)
    date.setMonth(month - 1)
    date.setDate(day)
    return date;
  }

  private _to2digit(n: number) {
    return ('00' + n).slice(-2);
  }
}
