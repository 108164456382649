import {SportEvent, UsersAvailability} from '../models/sport-events-page.models';
import {AVAILABLE_USERS_ADDITIONAL_KEY, USER_ROLES_KEYS} from '../../constants/sport-event.constant';
import {get} from 'lodash';

export class SportEventsSharedService {

  static removeUserAvailabilityForEventsByDate(user: string, events: SportEvent[], date: string, except?: SportEvent): void {
    SportEventsSharedService.getEventsByDate(date, events)
      .filter(event => event !== except)
      .forEach(event => {
        USER_ROLES_KEYS.forEach(roleKey => {
          const key = roleKey + AVAILABLE_USERS_ADDITIONAL_KEY;
          event[key] = event[key].filter(userName => user !== userName);
        })
      });
  }

  static getEventsByDate(dayCode: string, items: SportEvent[]) {
    return items.filter(i => i.dateToGetAvailableUsers === dayCode);
  }

  static addUserAvailabilityForEventsByDate(
    user: string,
    users: UsersAvailability,
    except: SportEvent,
    events: SportEvent[]
  ) {
    if(except.id === 58) debugger
    if (SportEventsSharedService.getRolesUserPresentIn(except, user).length) {
      return
    }

    return SportEventsSharedService.getEventsByDate(except.dateToGetAvailableUsers, events)
      .filter(event => event !== except)
      .forEach(event => {
        USER_ROLES_KEYS.forEach(roleKey => {
          const key = roleKey + AVAILABLE_USERS_ADDITIONAL_KEY;
          if (SportEventsSharedService.checkUserAvailabilityForRoleByDateAndSport(user, users, roleKey, event)) {
            event[key] = [...event[key], ...(user ? [user] : [])];
          }
        })
      })
  }

  static checkUserAvailabilityForRoleByDateAndSport(
    user: string,
    usersAvailability: UsersAvailability,
    roleKey: string,
    {sportName, dateToGetAvailableUsers}: SportEvent
  ): boolean {
    const users = get(usersAvailability, `${dateToGetAvailableUsers}.${sportName}.${roleKey}`) as any as string[] || [];
    return !!users.find(u => u === user);
  }

  static getEventsUserPresentInByDate(user: string, date: string, events: SportEvent[]): SportEvent[] {
    if (!user) {
      return [];
    }
    return events
      .filter(event => event.dateToGetAvailableUsers === date && USER_ROLES_KEYS.find(role => event[role] === user))
  }

  static getRolesUserPresentIn(event: SportEvent, username: string): string[] {
    return USER_ROLES_KEYS.filter(roleKey => username && event[roleKey] === username)
  }
}
