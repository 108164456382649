import {DatePipe} from '@angular/common';

export class Utils {
    static toFormatDate(date: string, format: string = 'dd.MM.yy') {
        const datePipe = new DatePipe('en-US');
        return datePipe.transform(date, format) || '';
    }

    static formatForBackend(date: string, withTime?: boolean) {
        return Utils.toFormatDate(date, `yyyy-MM-dd'${withTime ? 'THH:mm' : ''}`);
    }

    static uppercaseFirstChar(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static lowerCaseFirstLetter(string) {
        return string.charAt(0).toLowerCase() + string.slice(1);
    }
}
